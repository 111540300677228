<!-- New wallet modal -->
<div class="wallet-transaction-toggle transaction-container-expanded modal show all-in-wallet slideUp-content wallet-bg">
    <section class="wallet-heading">
        <h3 class="text-center m-b-0">
            {{'Game Wallet' | translate | uppercase }}
            <i class="fas fa-times" (click)="onCloseDialog()"></i>
        </h3>
    </section>
    <div id="walletContent" [formGroup]="quickDepositForm">
        <div class="type-select m-b-10 m-t-20">
            <a (click)="onSelectTab(1)" [ngClass]="activeTab === 1 ? ' active' : ''">{{ 'Balance' | translate }}</a>
            <a (click)="onSelectTab(2)" [ngClass]="activeTab === 2 ? ' active' : ''">{{ 'Turnover' | translate }}</a>
        </div>
        <!-- Balance -->
        <ng-container *ngIf="activeTab === 1">
            <ng-container *ngFor="let provider of providersList; let i = index">
                <div class="toggle m-t-20" [id]="provider.code" [ngClass]="provider.status != 1 ? 'bg-maintenance' : ''" *ngIf="provider.id != 0">
                    <div class="toggle-header" data-toggle="collapse" [attr.data-target]="provider.status == 1 ? '#'+provider.code+'Content' : ''" aria-expanded="true" [attr.aria-controls]="provider.status == 1 ? provider.code+'Content' : ''" appDebounceClick (debounceClick)="toggleGameProvider(provider, i)">
                        <div class="toggle-icon" [ngClass]="provider.status != 1 ? 'hide-icon' : ''">
                            <i class="toggle-closed icon-line-plus"></i>
                            <i class="toggle-open icon-line-minus"></i>
                        </div>
                        <div class="toggle-title">
                            <div class="row al-center">
                                <div class="col-3 p-l-0 p-r-0 provider-img">
                                    <ng-container *ngIf="provider.code === 'MAIN'; else notMain">
                                        <img [src]="provider.image_path">
                                    </ng-container>
                                    <ng-template #notMain>
                                        <img style="width:40px; height:auto;" [src]="provider.images[0]?.image_path?.icon_logo">
                                    </ng-template>
                                </div>
                                <div class="col-5 p-l-5 p-r-5 provider-name">
                                    <p>{{ provider.name | uppercase }}</p>
                                </div>
                                <div class="col-3 p-l-5 p-r-5">
                                    <span *ngIf="providerBalancesIsLoading > 0 || providerBalanceInRefresh.includes(provider.code); else balance" class="me-2 spinner-border spinner-border-sm align-middle spinner-right"></span>
                                    <ng-template #balance>
                                        <ng-container *ngIf="provider.balance === '-';  else showBalance">
                                            <p>{{ provider.balance }}</p>
                                        </ng-container>
                                        <ng-template #showBalance>
                                            <p>{{ wallet.currency_code }} {{ provider.balance }}</p>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                        <div class="refresh-balance" (click)="onRefreshProviderBalance(provider, $event)">
                            <i class="fas fa-redo-alt" ></i>
                        </div>
                    </div>

                    <div [id]="provider.code+'Content'" class="toggle-content" data-parent="#walletContent">
                        <div class="row al-center p-b-10 p-t-10">
                            <div class="col-5">{{ 'Quick Transfer' | translate }}</div>
                            <div class="col-7">
                                <button class="btn-all-in p-t-b-7" [disabled]="provider.status !== 1 || providerBalancesIsLoading > 0" (click)="onAllIn(provider.id, provider.code, i)">{{ 'All In' | translate | uppercase }}</button>
                            </div>
                        </div>

                        <div class="border1"></div>

                        <div class="row al-center p-b-5 p-t-10">
                            <div class="col-5">{{ 'Transfer' | translate }}</div>
                            <div class="col-7" style="position: relative;">
                                <input class="form-control form-control-sm transfer-amount-input enter-amount" type="number" [placeholder]="'Enter Amount' | translate" formControlName="amount" [attr.disabled]="(providerBalancesIsLoading > 0) ? true : null">
                                <button [disabled]="provider.status !== 1 || quickDepositForm.invalid || providerBalancesIsLoading > 0" (click)="onTransfer(provider.id)" class="btn-enter-amount"><i class="icon-chevron-right1"></i></button>
                            </div>
                        </div>

                        <div class="row al-center" *ngIf="(provider.code === 'KISS' || provider.code === 'MEGA' || provider.code === 'MEGAC')">
                            <div class="col-12" *ngIf="countryCode === 'ID'">
                                <p class="text-blue exchange-font">
                                    {{ 'Exchange Rate' | translate }} <br> IDR 1:0.2 {{ provider.name | uppercase }} {{ 'Credits' | translate }}
                                </p>
                            </div>
                            <div class="col-12" *ngIf="countryCode === 'MY'">
                                <p class="text-blue exchange-font">
                                    {{ 'Exchange Rate' | translate }} <br> MYR 1:1 {{ provider.name | uppercase }} {{ 'Credits' | translate }}
                                </p>
                            </div>
                            <div class="col-12" *ngIf="countryCode === 'SG'">
                                <p class="text-blue exchange-font">
                                    {{ 'Exchange Rate' | translate }} <br> SGD 1:1 {{ provider.name | uppercase }} {{ 'Credits' | translate }}
                                </p>
                            </div>
                            <div class="col-12" *ngIf="countryCode === 'TH'">
                                <p class="text-blue exchange-font">
                                    {{ 'Exchange Rate' | translate }} <br> THB 10:1 {{ provider.name | uppercase }} {{ 'Credits' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <!-- Turnover -->
        <ng-container *ngIf="activeTab === 2">
            <div class="toggle m-t-20">
                <div class="toggle-header">
                    <div class="toggle-title">
                        <div class="row al-center">
                            <div class="col-8 p-l-5 p-r-5 turnover d-flex">
                                <p>{{ 'Total Weekly Turnover' | translate }}</p>
                                <!-- <img [ngClass]="{ 'refresh-spin': isClicked }" src="/assets/images/refresh-blue.png" (click)="onRefreshTurnover()"> -->
                                <span [innerHTML]="svg.refreshIcon | safeHtml" [ngClass]="{ 'refresh-spin': isClicked }" (click)="onRefreshTurnover()"></span>
                            </div>
                            <div class="col-4 p-l-5  pr-3 d-flex justify-content-end">
                                <p>{{ wallet.currency_code }} {{ turnoverList.total_turnover | number : '1.2-2' }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="toggle m-t-20" *ngFor="let item of turnoverList | keyvalue">
                <ng-container *ngIf="item.key !== 'total_turnover'">
                    <div class="toggle-header">
                        <div class="toggle-title">
                            <div class="row al-center">
                                <div class="col-3 p-l-0 p-r-0 provider-img">
                                    <img *ngIf="item.value.filename !== null" [src]="toJSON(item.value.filename).icon_logo">
                                </div>
                                <div class="col-5 p-l-5 p-r-5 provider-name">
                                    <p>{{ item.value.game_provider_name }}</p>
                                </div>
                                <div class="col-4 p-l-5  pr-3 d-flex justify-content-end">
                                    <p>{{ wallet.currency_code }} {{ item.value.turnover | number: '1.2-2' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" [timer]="5000"></app-swal-alert>