import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import '@angular/compiler';
import { NgModule } from '@angular/core';
import { BankModalComponent } from '../views/modules/member/dialogs/bank-modal/bank-modal.component';
import { ClaimRewardModalComponent } from './../views/modules/member/dialogs/reward-modal/claim-reward-modal.component';
import { RewardSwalComponent } from './../views/modules/member/dialogs/reward-modal/reward-swal/reward-swal.component';
import { PromotionTooltipDialogComponent } from './../views/modules/member/dialogs/transfer-modal/promotion-tooltip/promotion-tooltip-modal.component';
import { TransferEditModalComponent } from './../views/modules/member/dialogs/transfer-modal/transfer-edit-modal/transfer-edit-modal.component';
import { PromotionApplicationComponent } from './../views/modules/promotion/dialogs/promotion-application/promotion-application.component';
import { RestoreInfoComponent } from './../views/partials/wallet-bar/dialogs/restore-info/restore-info.component';
import { GameSubSubComponentComponent } from './game-sub-sub-component/game-sub-sub-component.component';
import { PopupAnnouncementComponent } from './popup-announcement/popup-announcement.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { ForgotUsernameModalComponent } from './forgot-username-modal/forgot-username-modal.component';
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { DownloadAppModalComponent } from './download-app-modal/download-app-modal.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SwalAlertComponent } from './swal-alert/swal-alert.component';
import { WalletModalComponent } from './wallet-modal/wallet-modal.component';

import { GameListHttpService } from '@core/services/game-list-http.service';

import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { BankAccountDataService } from '@views/modules/member/services/bank-account-data.service';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { BankWrapperModalComponent } from './bank-wrapper-modal/bank-wrapper-modal.component';
import { LoggedValidatorComponent } from './logged-validator/logged-validator.component';
import { TransferWrapperModalComponent } from './transfer-wrapper-modal/transfer-wrapper-modal.component';

import { DebounceClickDirective } from '@core/directives/debounce-click.directive';
import { WithdrawVerificationModalComponent } from '@views/modules/member/dialogs/withdraw-verification-modal/withdraw-verification-modal.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { OrdinalDatePipe } from '../pipes/ordinal-date.pipe';
import { NgMarqueeModule } from 'ng-marquee';
import { CountDownComponent } from './count-down/count-down.component';
import { CountDownDetailsComponent } from './count-down/dialog/count-down-details/count-down-details.component';
import { DemoNoticeComponent } from './demo-notice/demo-notice.component';
import { FavoriteGameComponent } from './favorite-game/favorite-game.component';
import { InsufficientModalModalComponent } from './insufficient-funds-modal/insufficient-funds-modal.component';
import { MoreSearchComponent } from './more-search/more-search.component';
import { PopupDailyLimitOtpComponent } from './popup-daily-limit-otp/popup-daily-limit-otp.component';
import { SlotFishingCategoryModalComponent } from './slot-fishing-category-modal/slot-fishing-category-modal.component';
import { TinypreloaderComponent } from './tinypreloader/tinypreloader.component';
import { VpnNoticeComponent } from './vpn-notice/vpn-notice.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { PushNotificationCTAComponent } from './push-notification-cta/push-notification-cta.component';
import { SiteRouteComponent } from './site-route/site-route.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { Layout1Component } from './signup-layouts/layout1/layout1.component';
import { SectionContainer1Component } from './signup-layouts/layout1/section-container1/section-container1.componenet';
import { PromotionCancelModalComponent } from '@views/modules/member/dialogs/promotion-cancel-modal/promotion-cancel-modal.component';
import { FormatNameInitialsPipe } from 'app/pipes/format-name-initials.pipe';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { RequestReceiptComponent } from './request-receipt/request-receipt.component';
import { PopupConnectionErrorComponent } from './popup-connection-error/popup-connection-error.component';
import { SafePipe } from 'app/pipes/safe.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { FloatingRewardComponent } from './floating-reward/floating-reward.component';
import { EwalletVerificationModalComponent } from './ewallet-verification-modal/ewallet-verification-modal.component';
import { EwalletVerificationApprovalComponent } from './ewallet-verification-approval/ewallet-verification-approval.component';
import { EwalletVerificationRejectionComponent } from './ewallet-verification-rejection/ewallet-verification-rejection.component';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import { AppGameModalComponent } from './app-game-modal/app-game-modal.component';
import { FloatingKycNotificationComponent } from './floating-kyc-notification/floating-kyc-notification.component';

const modules = [
  AngularMultiSelectModule,
  CommonModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  NgbModule,
  DigitOnlyModule,
  NgMarqueeModule,
  TranslateModule,
  ClipboardModule,
  NgxUsefulSwiperModule,
  MatBadgeModule,
  QRCodeModule
];

const directives = [
  DebounceClickDirective
];
const pipes = [
  OrdinalDatePipe,
  FormatNameInitialsPipe,
  SafeHtmlPipe,
  SafePipe,
  FileCompressionPipe
];

const registrationPageLayouts = [
  Layout1Component,
  SectionContainer1Component
];

const dialogs = [
  BankWrapperModalComponent,
  ClaimRewardModalComponent,
  CountDownDetailsComponent,
  DemoNoticeComponent,
  PromotionTooltipDialogComponent,
  ForgotPasswordModalComponent,
  InsufficientModalModalComponent,
  LanguageModalComponent,
  LoginModalComponent,
  MoreSearchComponent,
  PopupDailyLimitOtpComponent,
  PromotionApplicationComponent,
  RewardSwalComponent,
  SignupModalComponent,
  SlotFishingCategoryModalComponent,
  GameSubSubComponentComponent,
  TransferWrapperModalComponent,
  WithdrawVerificationModalComponent,
  PromotionCancelModalComponent,
  WalletModalComponent,
  RestoreInfoComponent,
  VpnNoticeComponent,
  ForgotUsernameModalComponent,
  PushNotificationComponent,
  PushNotificationCTAComponent,
  SiteRouteComponent,
  DownloadAppModalComponent,
  PopupConnectionErrorComponent,
  EwalletVerificationModalComponent,
  EwalletVerificationApprovalComponent,
  EwalletVerificationRejectionComponent,
  AppGameModalComponent
];

const components = [
  ...directives,
  ...pipes,
  ...dialogs,
  ...registrationPageLayouts,
  SwalAlertComponent,
  PopupAnnouncementComponent,
  TransferEditModalComponent,
  BankModalComponent,
  LoggedValidatorComponent, // TODO: Remove and use swal modal instead!
  TransferWrapperModalComponent,
  BankWrapperModalComponent,
  TinypreloaderComponent,
  CountDownComponent,
  CountDownDetailsComponent,
  FavoriteGameComponent,
  DemoNoticeComponent,
  PromotionTooltipDialogComponent,
  RestoreInfoComponent,
  VpnNoticeComponent,
  SignupFormComponent,
  RequestReceiptComponent,
  FloatingRewardComponent,
  FloatingKycNotificationComponent,
];

const services = [
  GameListHttpService,
  PortalTransferHttpService,
  BankAccountDataService,
];

@NgModule({
  declarations: [
    ...components,
    MoreSearchComponent
  ],
  exports: [
    ...components,
    ...modules,
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    ...services
  ],
  entryComponents: [
    ...dialogs
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
