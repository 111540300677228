import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MemberBalance } from '@core/models/member-balance.model';
import { DashboardService } from '@core/services/dashboard.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { LoadingService } from '@core/services/loading-service.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/reducers';
import { of, Subscription, zip, forkJoin } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  // Notification Variable
  messages$ = this.transferService.messages$;
  isSuccess = this.transferService.isSuccess;

  // Form Variable
  quickDepositForm: FormGroup;

  // Balance Variable
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance
  wallet: MemberBalance;

  // Other varible
  providerBalancesIsLoading: number = 0;
  providersList = [];
  listTotal: number;
  originalHeight: number;
  countryCode = localStorage.getItem('country_code');
  activeTab = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) ? parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) : 1;
  isClicked = false;
  turnoverList: any;
  providerBalanceInRefresh = [];

  // To hold all subscription and unsubscribe when leaving the page 
  private subscriptions: Subscription[] = [];


  constructor(
    private transferService: PortalTransferHttpService,
    public dialogRef: MatDialogRef<WalletModalComponent>,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private activatedRoute: ActivatedRoute,
    private gameProviderHttpService: GameProviderHttpService,
    private loadingService: LoadingService,
    private translateService: TranslateService
  ) {
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.wallet = res;
        if (this.wallet.currency_code != '-' && this.gameProvidersBalance != undefined) {
          this.setList();
        }
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
        this.providersList = this.gameProvidersBalance.rows;
        if (this.gameProvidersBalance.rows.length > 0 && this.wallet != undefined) {
          this.setList();
        }
      })
    );
  }

  ngOnInit() {
    this.quickDepositFormInit();
    this.getTurnover();
  }

  ngOnDestroy() {
    this.subscriptions.map(sb => sb.unsubscribe());
  }

  quickDepositFormInit() {
    this.quickDepositForm = new FormGroup({
      amount: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null)
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSelectTab(tab: number) {
    this.activeTab = tab;
  }

  toggleGameProvider(provider, index) {
    if (provider.status === 1) {
      if (this.listTotal === (index + 1) || (this.listTotal - 2) === (index)) {
        if (this.originalHeight === document.getElementById('walletContent').offsetHeight) {
          document.getElementById('walletContent').style.height = `${this.originalHeight + 110}px`;
        }
        of(null).pipe(
          delay(150), tap(() => document.getElementById('walletContent').style.height = 'auto'
          )).subscribe();
      }

      $(`.toggle`).not(`#${provider.code}`).removeClass('toggle-active');
      $(`#${provider.code}`).closest('.toggle').siblings().find('.toggle-content').removeClass('show');
      $(`#${provider.code}`).toggleClass('toggle-active').find(`#${provider.code}Content`).toggleClass('show');

      document.getElementById(provider.code).scrollIntoView({ behavior: 'smooth', block: 'center' });

      this.quickDepositForm.patchValue({
        amount: null,
        promotion_id: 0
      });
    }
  }

  onAllIn(provider: number, code: string, ind: number) {
    const transfers = [];
    this.gameProvidersBalance.rows.forEach(row => {
      if (row.balance > 0.00 && row.status === 1) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });
    if (transfers.length > 0) {
      zip(...transfers).pipe(
        tap(() => {
          this.allInAProvider(provider, code, ind);
        }),
        catchError((error) => {
          this.refreshBalance();
          throw error;
        })
      ).subscribe();
    } else {
      this.allInAProvider(provider, code, ind);
    }
  }

  onTransfer(providerId: number) {
    const data = {
      amount: this.quickDepositForm.value.amount,
      transfer_from: 0, // Main wallet
      promotion_id: 0,  // Default
      transfer_to: providerId
    }
    this.submitData(data);
  }

  onRefreshTurnover() {
    this.isClicked = true;
    this.getTurnover();
  }

  toJSON(content: string) {
    if (content == undefined || content == null || content == '') {
      return {}
    } else {
      return JSON.parse(content);
    }
  }

  private setList() {
    this.providersList = this.gameProvidersBalance.rows.slice(); // Use slice to clone
    this.providersList.unshift({ ...this.wallet, id: 0, image_path: 'assets/images/vendor/main-wallet.png', status: 1, name: this.translateService.instant('Main Wallet') });
    this.listTotal = this.providersList.length;
    of(null).pipe(
      delay(500), tap(() => this.originalHeight = document.getElementById('walletContent').offsetHeight
      )).subscribe();
  }

  private getTurnover() {
    this.gameProviderHttpService.getWeeklyTurnover().subscribe(res => {
      this.turnoverList = res;
      this.isClicked = false;
    });
  }

  private allInAProvider(provider: number, code: string, ind: number) {
    this.subscriptions.push(
      this.transferService.transferBy(provider, 'all', 1).pipe(
        tap(res => {
          this.isSuccess = res.success;
          this.messages$.next(res.message);
          if (res.success) {
            this.refreshBalance();
          }
        }),
        catchError((error) => {
          this.refreshBalance();
          throw error;
        })
      ).subscribe()
    );
  }

  private refreshBalance() {
    forkJoin([
      this.gameCategoryHttpService.getGameCategoryListWithPromo(true),
      this.walletHttpService.getMemberBalance()
    ]).subscribe();
    this.quickDepositFormInit();
  }

  private submitData(data: any) {
    this.transferService.transfer(data).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.refreshBalance();
      }),
      catchError((error) => {
        this.refreshBalance();
        throw error;
      })
    ).subscribe();
  }

  onRefreshProviderBalance(provider, event: Event) {
    event.stopImmediatePropagation();
    let balances = JSON.parse(sessionStorage.getItem('game_providers_balance'));
    this.providerBalanceInRefresh.push(provider.code);
    this.gameCategoryHttpService.getProviderBalanceBy(provider.code).pipe(
      tap(res => {
        balances.rows.map((balance) => {
          if (balance.code === provider.code) {
            balances['total_game_balance'] -= balance.balance;
            balance.balance = res;
            balances['total_game_balance'] += res;
          }
        })

        // Push the latest data into gameProvidersBalance BehaviorSubject
        this.gameCategoryHttpService.pushData(balances);

        // Set latest data into session storage
        sessionStorage.setItem('game_providers_balance', JSON.stringify(balances));
        this.providerBalanceInRefresh = this.providerBalanceInRefresh.filter(code => {return code != provider.code});
      })
    ).subscribe();
  }
}
