import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Currency } from '@core/models/currency.model';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { AppState } from '@store/reducers';
import { Subject, zip, forkJoin, Subscription, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { PromotionTooltipDialogComponent } from './../../dialogs/transfer-modal/promotion-tooltip/promotion-tooltip-modal.component';
import { LoadingService } from '@core/services/loading-service.service';
import { MemberBalance } from '@core/models/member-balance.model';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-portal-transfer',
  templateUrl: './portal-transfer.component.html',
  styleUrls: ['./portal-transfer.component.scss']
})
export class PortalTransferComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  // Notification Variable
  messages$ = this.transferService.messages$;
  isSuccess = this.transferService.isSuccess

  // Form Variable
  form: FormGroup;
  quickDepositForm: FormGroup;

  // Balance Variable
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance
  wallet: MemberBalance;
  turnoverList: any;
  providersList = [];

  // Promotion Variable
  isPromotionLoading: boolean = false;
  promotionList = [];
  quickPromotionList = [];
  selectedPromotion = null;
  quickSelectedPromo = null;

  // Toggle Variable
  transferFromExpanded = false;
  transferToExpanded = false;
  walletExpanded = false;
  transferAmountExpanded = false;
  promotionExpanded = false;

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;
  selectedTransferFrom: any;
  selectedTransferTo: any;
  isClicked = false;
  checkValidity = false;
  checkQuickDepositValidity = false;
  isFromMega918 = false;
  isToMega918 = false;
  showExchangeRate = new Subject<boolean>();
  amountButton = [50, 100, 200, 300, 500, 1000];
  windowInnerHeight: number;
  promoFieldVisible = false;
  countryCode = localStorage.getItem('country_code');
  activeTab = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) ? parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) : 1;
  selectedMainWallet = 'assets/images/vendor/main-wallet-selected.png';
  promoTransferSuccess = false;
  customClass: SweetAlertCustomClass = {
    popup: 'customPopupMaintenance',
    content: 'customContent'
  };
  providerBalanceInRefresh = [];

  // To hold all subscription and unsubscribe when leaving the page 
  private subscriptions: Subscription[] = [];

  constructor(
    private transferService: PortalTransferHttpService,
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private gameCategoryHttpService: GameCategoryHttpService,
    private activatedRoute: ActivatedRoute,
    private gameProviderHttpService: GameProviderHttpService,
    private walletHttpService: WalletHttpService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private loadingService: LoadingService
  ) {
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
        // if (this.providerBalancesIsLoading > 0) {
        //   this.form?.get('amount').disable();
        // } else {
        //   this.form?.get('amount').enable();
        // }
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.wallet = res;
        if (this.wallet.currency_code != '-' && this.gameProvidersBalance != undefined) {
          this.resetAll();
          this.setList();
        }
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
        this.providersList = this.gameProvidersBalance.rows;
        if (this.gameProvidersBalance.rows.length > 0 && this.wallet != undefined) {
          this.resetAll();
          this.setList();
        }
      })
    );
  }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerHeight < this.windowInnerHeight) {
      window.scroll(0, 320);
    }
  }

  ngOnInit() {
    this.refreshBalance(window.history.state.fromWalletPage);
    this.windowInnerHeight = window.innerHeight;
    this.formInit();
    this.quickDepositFormInit();
    this.getTurnover();
  }

  ngOnDestroy() {
    this.subscriptions.map(sb => sb.unsubscribe());
  }

  onSelectTab(tab: number) {
    this.activeTab = tab;
  }

  onAutoCollapse(field: string) {
    setTimeout(() => {
      if (field === 'amount' && this.transferAmountExpanded === true) {
        this.transferAmountExpanded = false;
      } else if (field === 'transferTo' && this.transferToExpanded === true) {
        this.transferToExpanded = false;
      } else if (field === 'wallet' && this.walletExpanded === true) {
        $(`.toggle`).removeClass('toggle-active');
        // $('.toggle-content').toggle('hide');
        // $('#walletContent').toggle('hide');
        this.walletExpanded = false;
      } else if (field === 'transferFrom' && this.transferFromExpanded === true) {
        this.transferFromExpanded = false;
      } else if (field === 'promotion' && this.promotionExpanded === true) {
        this.promotionExpanded = false;
      }
    }, 100);
  }

  onAllIn(providerId: number) {
    const data = {
      amount: 'all',
      transfer_from: 0,
      transfer_to: providerId
    };
    this.submitData(data, true);
  }

  onTransfer(providerId: number) {
    this.checkQuickDepositValidity = true;
    if (this.quickDepositForm.valid) {
      const data = {
        amount: this.quickDepositForm.value.amount,
        promotion_id: +this.quickDepositForm.value.promotion_id,
        transfer_from: 0,
        transfer_to: providerId
      }
      this.submitData(data, false);
    }
  }

  onSelectQuickPromo() {
    const promoId = +this.quickDepositForm.get('promotion_id').value
    if (promoId === 0) {
      this.quickSelectedPromo = null;
    } else {
      this.quickSelectedPromo = this.quickPromotionList.find(x => +x.id === promoId);
      // Target type = Winloss incl. Transfer Amount 
      // Set the multiplier+1 due to Target Amount = (Game Wallet Balance + Transfer Amount + Bonus) x Game Multiplier
      if (this.quickSelectedPromo.target_type == 2) {
        this.quickSelectedPromo.target_multiplier = this.quickSelectedPromo.target_multiplier + 1;
      }
    }
  }

  onRefreshTurnover() {
    this.getTurnover();
  }

  toJSON(content: string) {
    if (content === undefined || content === null) {
      return {}
    } else {
      return JSON.parse(content).icon_logo;
    }
  }

  onSelectTransferFrom(provider) {
    this.checkValidity = false;
    if (provider.status === 1 && provider?.suspend_transfer_out_status !== 1) {
      this.selectedTransferFrom = provider;
      if (provider.has_promo === 1) { }
      this.form.patchValue({
        amount: provider.balance,
        transfer_from: provider.id
      });
      this.transferFromExpanded = !this.transferFromExpanded;
      if (this.selectedTransferTo !== undefined || this.selectedTransferFrom.id === this.selectedTransferTo?.id) {
        this.selectedTransferTo = undefined;
        this.form.patchValue({
          transfer_to: null
        });
        this.isToMega918 = false;
        this.form.get('promotion_id').clearValidators();
        this.form.get('promotion_id').updateValueAndValidity();
      }

      if ((provider.code === 'KISS' || provider.code === 'MEGA' || provider.code === 'MEGAC')) {
        this.isFromMega918 = true;
      } else {
        this.isFromMega918 = false;
      }

      if ((this.isToMega918 && this.isFromMega918) || (!this.isToMega918 && !this.isFromMega918)) {
        this.showExchangeRate.next(false);
      } else if (this.isToMega918 && !this.isFromMega918) {
        this.showExchangeRate.next(true);
      } else if (this.isFromMega918 && !this.isToMega918) {
        this.showExchangeRate.next(true);
      }
    } else {
      this.onSwalFire(provider);
    }
  }

  onSelectTransferTo(provider) {
    this.selectedPromotion = null;
    this.form.patchValue({
      promotion_id: null
    });
    if (provider.status === 1 && provider?.suspend_transfer_in_status !== 1) {
      this.selectedTransferTo = provider;
      this.form.patchValue({
        transfer_to: provider.id,
        promotion_id: null
      });
      this.transferToExpanded = !this.transferToExpanded;
      this.promoFieldVisible = true;
      if (provider.has_promo === 1) {
        this.setPromotionList(provider);
      } else {
        // $('.promoDiv').animate({ 'min-height': 0 });
        this.form.get('promotion_id').clearValidators();
        this.form.get('promotion_id').updateValueAndValidity();
      }

      if ((provider.code === 'KISS' || provider.code === 'MEGA' || provider.code === 'MEGAC')) {
        this.isToMega918 = true;
      } else {
        this.isToMega918 = false;
      }

      if ((this.isToMega918 && this.isFromMega918) || (!this.isToMega918 && !this.isFromMega918)) {
        this.showExchangeRate.next(false);
      } else if (this.isToMega918 && !this.isFromMega918) {
        this.showExchangeRate.next(true);
      } else if (this.isFromMega918 && !this.isToMega918) {
        this.showExchangeRate.next(true);
      }
    } else {
      this.onSwalFire(provider);
    }
  }

  onSubmitForm() {
    if (sessionStorage.getItem('member_bank_accounts') === "[]" || sessionStorage.getItem('member_bank_accounts') === null) {
      this.dialog.open(BankWrapperModalComponent, {
        width: 'auto',
        data: {
          is_transfer: true
        }
      });
    }
    else {
      this.checkValidity = true;
      if (this.form.valid) {
        const data: tranferFormData = {
          amount: this.form.value.amount,
          promotion_id: this.form.value.promotion_id === null ? 0 : this.form.value.promotion_id,
          transfer_from: this.form.value.transfer_from,
          transfer_to: this.form.value.transfer_to
        }
        // If there is no promotion to be applied, do normal transfer function
        if (data.promotion_id == 0) {
          this.submitData(data, false);
        } else {
          // If there is promotion claimed
          // First we find the wallet balance that user want to transfer to
          const game_balance = this.providersList.find(x => x.id == data.transfer_to);
          // If found and the wallet balance is greater than 0
          if (game_balance && game_balance.balance > 0) {
            // Do confirmation pop up
            this.alertPop(game_balance, data);
          } else {
            // If wallet balance is 0, do normal transfer function
            this.submitData(data, false);
          }
        }
      }
    }
  }

  onSwap() {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0 && this.selectedTransferTo['id'] !== undefined && this.selectedTransferFrom['id'] !== undefined) {
      this.promotionList = [];
      this.transferToExpanded = true;
      this.transferFromExpanded = true;
      const selectedTransferFrom = this.selectedTransferFrom;
      const selectedTransferTo = this.selectedTransferTo;
      this.onSelectTransferFrom(selectedTransferTo);
      this.onSelectTransferTo(selectedTransferFrom);
    }
  }

  onSelectAmount(amount: number) {
    this.form.patchValue({
      amount: amount
    })
  }

  onSelectPromotion(promotion: any, ind: any) {
    const data = {
      ...promotion,
      name: promotion.name === null ? (this.translateService.instant('Promotion') + ' ' + ind) : promotion.name
    }
    this.selectedPromotion = data;
    // Target type = Winloss incl. Transfer Amount 
    // Set the multiplier+1 due to Target Amount = (Game Wallet Balance + Transfer Amount + Bonus) x Game Multiplier
    if (promotion.target_type == 2) {
      this.selectedPromotion['target_multiplier'] = promotion.target_multiplier + 1;
    }
    this.form.patchValue({
      promotion_id: promotion.id
    });
    if (promotion.promo_type === 4) {
      this.form.controls['amount'].setValidators(Validators.min(0));
    } else {
      this.form.controls['amount'].setValidators(Validators.min(1));
    }
    this.form.get('amount').updateValueAndValidity();
    this.promotionExpanded = false;
  }

  onMultiplicand(formAmount: any, selectedPromo: any, provider?: any) {
    var finalBonus = this.onBonusAmount(formAmount, selectedPromo);
    var targetTypeValue = selectedPromo['target_type_name'].includes('incl') ? formAmount : 0; // 0 for exclude
    // Comment out to not include the balance in game provider wallet for turnover calculation
    // const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus) + Number(provider ? provider['balance'] : this.selectedTransferTo['balance'])).toFixed(3).slice(0,-1));
    const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus)).toFixed(3).slice(0, -1));
    var finalTotalTransferBonusAmount = Number(totalTransferBonusAmount);
    return Number(finalTotalTransferBonusAmount);
  }

  onTotalTurnOver(transferAmount: number, selectedPromo: any, provider?: any) {
    const finalTotalTransferBonusAmount = this.onMultiplicand(transferAmount, selectedPromo, provider);
    return Number(finalTotalTransferBonusAmount) * Number(selectedPromo.target_multiplier);
  }

  onBonusAmount(formAmount: any, selectedPromo: any) {
    const totalBonus = (formAmount * selectedPromo.bonus_rate).toFixed(3).slice(0, -1);
    var finalBonus = +totalBonus;
    if (+selectedPromo['max_bonus'] < +totalBonus && +selectedPromo['max_bonus'] > 0) {
      finalBonus = +selectedPromo['max_bonus'];
    }
    return finalBonus;
  }

  // Begin: Toggle Part
  toggle(name: string) {

    const walletToggle = () => {
      if (!this.walletExpanded) {
        $(`.toggle`).removeClass('toggle-active');
        // $('.toggle-content').toggle('hide');
        // $('#walletContent').toggle('hide');
        this.quickSelectedPromo = null;
        this.quickDepositForm.patchValue({
          amount: null,
          promotion_id: 0
        });
      }

      setTimeout(() => {
        if ($(`.wallet-transaction-toggle`).find('.show').length > 0) {
          this.walletExpanded = true;
        } else {
          this.walletExpanded = false;
        }
      }, 500);
    }

    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      switch (name) {
        case 'wallet':
          this.walletExpanded = !this.walletExpanded;
          this.transferFromExpanded = false;
          this.transferToExpanded = false;
          this.transferAmountExpanded = false;
          this.promotionExpanded = false;
          walletToggle();
          break;
        case 'transferFrom':
          this.walletExpanded = false;
          this.transferFromExpanded = !this.transferFromExpanded;
          this.transferToExpanded = false;
          this.transferAmountExpanded = false;
          this.promotionExpanded = false;
          walletToggle();
          break;
        case 'transferTo':
          this.walletExpanded = false;
          this.transferFromExpanded = false;
          this.transferToExpanded = !this.transferToExpanded;
          this.transferAmountExpanded = false;
          this.promotionExpanded = false;
          walletToggle();
          break;
        case 'transferAmount':
          this.walletExpanded = false;
          this.transferFromExpanded = false;
          this.transferToExpanded = false;
          this.transferAmountExpanded = !this.transferAmountExpanded;
          this.promotionExpanded = false;
          walletToggle();
          break;
        case 'promotion':
          if (this.selectedTransferTo['has_promo'] === 1) {
            this.walletExpanded = false;
            this.transferFromExpanded = false;
            this.transferToExpanded = false;
            this.transferAmountExpanded = false;
            this.promotionExpanded = !this.promotionExpanded;
            walletToggle();
          }
          break;
      }
    }
  }

  toggleGameProvider(provider) {
    this.checkQuickDepositValidity = false;
    if (provider.status === 1 && provider?.suspend_transfer_in_status !== 1) {
      $(`.toggle`).not(`#${provider.code}`).removeClass('toggle-active');
      $(`#${provider.code}`).closest('.toggle').siblings().find('.toggle-content').removeClass('show');
      $(`#${provider.code}`).toggleClass('toggle-active').find(`#${provider.code}Content`).toggleClass('show');

      document.getElementById(provider.code).scrollIntoView({ behavior: 'smooth', block: "center" });
      this.quickDepositForm.patchValue({
        amount: null,
        promotion_id: 0
      });
      this.quickSelectedPromo = null;
      this.setPromotionList(provider, true);
    } else {
      this.onSwalFire(provider);
    }
  }
  // End: Toggle Part

  private formInit() {
    this.form = new FormGroup({
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      promotion_id: new FormControl(null),
      transfer_from: new FormControl(null, [Validators.required]),
      transfer_to: new FormControl(null, [Validators.required])
    });
  }

  private quickDepositFormInit() {
    this.quickDepositForm = new FormGroup({
      amount: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null)
    });
  }

  private getTurnover() {
    this.isClicked = true;
    this.gameProviderHttpService.getWeeklyTurnover().subscribe(res => {
      this.turnoverList = res;
      this.isClicked = false;
    });
  }

  private setList() {
    this.providersList = this.gameProvidersBalance.rows.slice();
    this.providersList.unshift({ ...this.wallet, id: 0, image_path: 'assets/images/vendor/main-wallet.png', status: 1, suspend_transfer_in_status: 0, suspend_transfer_out_status: 0, name: this.translateService.instant('Main Wallet') });
    this.transferFromExpanded = true;
    this.onSelectTransferFrom({ ...this.wallet, id: 0, image_path: 'assets/images/vendor/main-wallet.png', status: 1, suspend_transfer_in_status: 0, suspend_transfer_out_status: 0, name: this.translateService.instant('Main Wallet') });
  }

  private setPromotionList(provider, isGameWalletTab = false) {
    this.isPromotionLoading = true;
    isGameWalletTab ? this.quickSelectedPromo = null : this.selectedPromotion = null;

    this.transferService.getPromotions('?game_provider_id=' + provider.id).pipe(
      tap(res => {
        if (isGameWalletTab) {
          this.quickPromotionList = res;
          this.quickPromotionList.unshift({
            id: 0,
            name: 'None'
          });

          if (this.quickPromotionList.length > 1) {
            this.quickDepositForm.get('promotion_id').setValidators([Validators.required]);
            this.quickDepositForm.get('promotion_id').updateValueAndValidity();
          } else {
            this.quickDepositForm.get('promotion_id').clearValidators();
            this.quickDepositForm.get('promotion_id').updateValueAndValidity();
          }
        } else {
          this.promotionList = res;
          this.promotionList.unshift({
            id: 0,
            name: 'None'
          });

          if (this.promotionList.length > 1) {
            // $('.promoDiv').animate({ 'min-height': '113px' });
            this.form.get('promotion_id').setValidators([Validators.required]);
            this.form.get('promotion_id').updateValueAndValidity();
          } else {
            // $('.promoDiv').animate({ 'min-height': 0 });
            this.form.get('promotion_id').clearValidators();
            this.form.get('promotion_id').updateValueAndValidity();
          }
        }
        this.isPromotionLoading = false;
      }),
      catchError(err => {
        this.isPromotionLoading = false;
        return throwError(err);
      })
    ).subscribe();
  }

  private refreshBalance(fromWalletPage: boolean = false) {
    forkJoin([
      this.gameCategoryHttpService.getGameCategoryListWithPromo(!fromWalletPage),
      this.walletHttpService.getMemberBalance()
    ]).subscribe();
  }

  private resetAll() {
    this.formInit();
    this.quickDepositFormInit();
    this.selectedPromotion = null;
    this.quickSelectedPromo = null;
    this.checkValidity = false;
    this.checkQuickDepositValidity = false;
    this.promoFieldVisible = false;
  }

  private submitData(data: any, allIn = false) {
    if (!allIn) {
      this.transferService.transfer(data).subscribe(res => {
        this.isSuccess = res.success;
        // If there is no promotion claimed, use the old success message pop up
        if (res.data.rows.promotion_id == 0) {
          this.messages$.next(res.message);
        } else {
          // If there is a promotion claimed, use new message pop up
          this.sucessPromoPopUp(res);
        }
        this.checkValidity = false;
        this.quickSelectedPromo = null;
        this.refreshBalance();
      })
    } else {
      const dataArray = [];
      this.providersList.filter(x => x.balance > 0 && x.id != 0).forEach(item => {
        dataArray.push(this.transferService.transfer({
          amount: 'all',
          transfer_from: item.id,
          transfer_to: 0
        }));
      });
      if (dataArray.length == 0) {
        this.transferService.transfer(data).subscribe(res => {
          this.isSuccess = res.success;
          this.messages$.next(res.message);
          this.checkValidity = false;
          this.quickSelectedPromo = null;
          this.refreshBalance();
        })
      } else {
        zip(...dataArray).subscribe(() => {
          this.transferService.transfer(data).subscribe(res => {
            this.isSuccess = res.success;
            this.messages$.next(res.message);
            this.checkValidity = false;
            this.quickSelectedPromo = null;
            this.refreshBalance();
          })
        });
      }
    }
  }

  private sucessPromoPopUp(res: any) {
    // The wallet balance will be transferr amount + bonus amount as claiming promotion, the wallet balance will be always 0
    let new_wallet_balance = +res.data.rows.amount + +res.data.rows.bonus_amount;
    // Add currency code to the wallet balance
    const new_wallet_balance_text = this.wallet.currency_code + ' ' + new_wallet_balance.toFixed(2).toString();
    // Display confirmation popup
    Swal.fire({
      html: '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' +
        '<div class="text-center m-t-20">' +
        this.translateService.instant('Congratulations') +
        '</div>' + '<br>' +
        '<div class="text-center">' +
        this.translateService.instant('The promotion has been successfully applied.') +
        '</div>' +
        "<hr style='border-top: 1px solid;'>" +
        "<div class='form-input-label text-left'>" +
        "<p>" + this.translateService.instant('Game Provider') + "</p>" +
        "</div>" +
        "<div class='input_container'>" +
        "<input type='text' class='form-control form-control-sm form-input-style' value='" + res.data.to_name + "' />" +
        "</div>" +
        "<div class='form-input-label text-left'>" +
        "<p>" + this.translateService.instant('New Wallet Balance') + "</p>" +
        "</div>" +
        "<div class='input_container'>" +
        "<input type='text' class='form-control form-control-sm form-input-style' value='" + new_wallet_balance_text + "' />" +
        "</div>"
    });
  }

  private alertPop(gameData: GameBalance, formData: tranferFormData) {
    // Display confrimation message
    Swal.fire({
      html: "<div class='form-input-label text-left'>" +
        "<p>" + this.translateService.instant('Game Provider') + "</p>" +
        "</div>" +
        "<div class='input_container'>" +
        "<input type='text' class='form-control form-control-sm form-input-style' value='" + gameData.name + "' />" +
        "</div>" +
        "<div class='form-input-label text-left'>" +
        "<p>" + this.translateService.instant('Game Wallet Balance') + "</p>" +
        "</div>" +
        "<div class='input_container'>" +
        "<input type='text' class='form-control form-control-sm form-input-style' value='" + gameData.balance + "' />" +
        "</div>" +
        '<div class="text-center m-t-20" style="color: #0066c9 !important; font-size: 11px">' +
        this.translateService.instant('You have a remaining balance in the selected game wallet.') + '<br>' +
        this.translateService.instant('This balance will be transferred back to the main wallet before the promotion application.') +
        '</div>' + '<br>' +
        '<div class="text-center" style="font-size: 11px">' +
        this.translateService.instant('Click YES to proceed with the transfer and promotion application.') +
        '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      showCloseButton: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {
      // If yes
      if (result.isConfirmed) {
        // Prepare param for transferring the remaining game wallet balance to main wallet first
        this.promoTransferSuccess = false;
        const dataFromGameToMain = {
          amount: gameData.balance,
          promotion_id: 0,
          transfer_from: gameData.id,
          transfer_to: 0
        }
        this.submitDataTwo(dataFromGameToMain).toPromise()
          .then(() => {
            if (this.promoTransferSuccess) {
              const dataFromMainToGameWithPromo = formData;
              this.submitData(dataFromMainToGameWithPromo);
            }
          })
          .catch((err) => {
            throw err;
          });

      }
    });
  }

  private onSwalFire(provider) {
    let message = null;

    if (provider.status != 1) {
      message = 'Under Maintenance'
    } else {
      message = 'Transfer Suspended'
    }

    Swal.fire({
      icon: 'info',
      iconHtml: '!',
      html: '<div class="text-center">' + this.translateService.instant(message) + ' </div>',
      toast: true,
      showConfirmButton: false,
      customClass: this.customClass,
      position: 'top',
      timer: 3000,
      hideClass: {
        popup: `animate__animated animate__bounceOutUp`
      },
    });
  }

  private submitDataTwo(data: any): Observable<any> {
    return new Observable(observer => {
      this.transferService.transfer(data).subscribe(res => {
        this.promoTransferSuccess = res.success;
        observer.next(res);
        observer.complete();
      })
    });

  }

  onRefreshProviderBalance(provider, event: Event) {
    event.stopImmediatePropagation();
    let balances = JSON.parse(sessionStorage.getItem('game_providers_balance'));
    this.providerBalanceInRefresh.push(provider.code);
    this.gameCategoryHttpService.getProviderBalanceBy(provider.code).pipe(
      tap(res => {
        balances.rows.map((balance) => {
          if (balance.code === provider.code) {
            balances['total_game_balance'] -= balance.balance;
            balance.balance = res;
            balances['total_game_balance'] += res;
          }
        })

        // Push the latest data into gameProvidersBalance BehaviorSubject
        this.gameCategoryHttpService.pushData(balances);

        // Set latest data into session storage
        sessionStorage.setItem('game_providers_balance', JSON.stringify(balances));
        this.providerBalanceInRefresh = this.providerBalanceInRefresh.filter(code => {return code != provider.code});
      })
    ).subscribe();
  }
}

class GameBalance {
  balance: string;
  name: string;
  code: string;
  id: number;
}

class tranferFormData {
  amount: number;
  promotion_id: number;
  transfer_from: number;
  transfer_to: number;
}
