import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  private tabsOpenKey = 'tabsOpenCount';

  constructor() {
    // Initialize the tabs count when a new tab opens
    const currentTabs = Number(localStorage.getItem(this.tabsOpenKey)) || 0;
    localStorage.setItem(this.tabsOpenKey, (currentTabs + 1).toString());
  }

  // Decrease the tab count and clean up if no tabs remain
  decreaseTabCount(backupKey) {
    const currentTabs = Number(localStorage.getItem(this.tabsOpenKey)) || 0;
    if (currentTabs > 1) {
      localStorage.setItem(this.tabsOpenKey, (currentTabs - 1).toString());
    } else {
      // If no tabs remain, clear the backup
      localStorage.removeItem(backupKey);
      localStorage.removeItem(this.tabsOpenKey);
    }
  }
}
