import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import svgIconList from 'assets/icons.json';
import { environment } from '@env/environment';


@Component({
  selector: 'app-portal-navigation',
  templateUrl: './portal-navigation.component.html',
  styleUrls: ['./portal-navigation.component.scss']
})

export class PortalNavigationComponent implements OnInit {
  svg: any = svgIconList;
  member_KYC = null;
  userData = JSON.parse(localStorage.getItem('user_data'));
  verificationRejected = localStorage.getItem('rejected_verification');

  sitePrefix: string;
  hideReferralSite = ['12HUAT', 'BX99', 'YE55', 'U388']; // QPRO2, QPRO3, QPRO4, QPRO5

  constructor(
    private eventEmitterService: EventEmitterService,
) { }

  ngOnInit() {
    this.sitePrefix = environment.sitePrefix.toUpperCase();

    this.eventEmitterService.updateVerificationStorageEmitter.subscribe(() => {
      this.verificationRejected = localStorage.getItem('rejected_verification'); 
    });
    if(this.userData){
      this.member_KYC = this.userData.KYC;
    }
  }
}
