import { Component, OnInit, Inject, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-request-receipt',
  templateUrl: './request-receipt.component.html',
  styleUrls: ['./request-receipt.component.scss']
})
export class RequestReceiptComponent implements OnInit, AfterViewInit {
  svg: any = svgIconList;
  @ViewChild('myFileInput') myFileInput;
  @ViewChild('stepper') stepper;

  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  messages$ = this.requestReceiptHttpService.messages$;

  minimizeDialog = true;
  showBallon = true;
  depositData: RequestReceipt = null;

  uploadInProgress: boolean = false;
  fileName: string = null;

  form: FormGroup;
  formSubmitting: boolean = false;
  max_receipts = 10;
  uploaded_receipts = [];
  uploaded_receipts_form = [];
  isCompressed = false;

  constructor(
    private translateService: TranslateService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private dropdownHttpService: DropdownHttpService,
    private uploadHttpService: UploadHttpService,
    private sanitizer: DomSanitizer,
    private fileCompression: FileCompressionPipe,
  ) { }

  @HostListener('window:resize', ['$event'])
  setRequestDialog(event?) {
    var requestDialog = document.getElementById('request-dialog');
    requestDialog.style.top = (window.innerHeight - requestDialog.offsetHeight) / 2 + 'px';
    requestDialog.style.left = '0';
    if (this.minimizeDialog) {
      var ballon = document.getElementsByClassName('receipt-floating-content')[0].getBoundingClientRect(),
        requestDialog = document.getElementById('request-dialog');
      requestDialog.style.top = ballon.top - (requestDialog.offsetHeight / 2) + 'px'
      requestDialog.style.left = ballon.left - (requestDialog.offsetWidth / 2) + 'px';
    }
  }

  ngOnInit() {
    this.formInit();
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
      this.onTogglePopup(this.depositData ? true : false);
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setRequestDialog();
    }, 500);
  }

  onTogglePopup(forceExpand: boolean = false) {
    this.minimizeDialog = forceExpand ? false : !this.minimizeDialog;
    if (this.depositData == null) this.minimizeDialog = true;
    this.setRequestDialog()
    this.showBallon = true;
  }

  onCloseBallon() {
    this.showBallon = false;
  }

  onCancelDeposit() {
    Swal.fire({
      html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
        '<div class="text-center m-t-20">' +
        '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this deposit submission?') + '</li></ul>' +
        '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {
      if (result.isConfirmed) {
        this.formSubmitting = true;
        const data = {
          status: 2 // 2 = Rejected Status
        };
        this.requestReceiptHttpService.update(this.depositData.deposit_id, data).pipe(
          tap(res => {
            this.formSubmitting = false;
            this.resetValue();
            setTimeout(() => {
              this.onTogglePopup();
              this.onCloseBallon();
            }, 500);
          }),
          catchError(err => {
            this.formSubmitting = false;
            throw err;
          })
        ).subscribe();
      }
    });
  }

  async onUploadFileMulti(event:any) {
    this.uploadInProgress = true;
    const file: File = event.target.files[0];
    if (file == null)  {
      this.uploadInProgress = false;
      return;
    }
    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }
    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownHttpService.uploadTypes[2]);
      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress = false;
          this.uploaded_receipts.push({
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          });
          this.uploaded_receipts_form.push(res[0]);
          this.form.patchValue({
            receipt_path: this.uploaded_receipts_form
          });
          this.form.get('receipt_path').markAsDirty();
          this.form.markAllAsTouched();
        }),
        catchError((error) => {
          this.uploadInProgress = false;
          throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
    }
    event.target.value = null;
  }

  onRemoveFileMulti(idx) {
    this.uploaded_receipts.forEach((value,index)=>{
      if(index==idx) this.uploaded_receipts.splice(index,1);
    });
    this.uploaded_receipts_form.forEach((value,index)=>{
      if(index==idx) this.uploaded_receipts_form.splice(index,1);
    });
    this.form.patchValue({
      receipt_path: this.uploaded_receipts_form
    });
    this.form.get('receipt_path').markAsDirty();
    this.form.markAllAsTouched();
  }

  onSubmit() {
    if (this.form.valid) {
      const data = {
        ...this.form.value,
        status: 0 // 0 = Pending Status
      };
      this.requestReceiptHttpService.update(this.depositData.deposit_id, data).pipe(
        tap(res => {
          this.formSubmitting = false;
          this.resetValue();
          setTimeout(() => {
            this.onTogglePopup();
            this.onCloseBallon();
          }, 500);
        }),
        catchError(err => {
          this.formSubmitting = false;
          throw err;
        })
      ).subscribe();
    }
  }

  private resetValue() {
    this.stepper.reset();
    this.uploaded_receipts = [];
    this.uploaded_receipts_form = [];
    this.form.patchValue({
      receipt_path: null
    });
  }

  private formInit() {
    this.form = new FormGroup({
      receipt_path: new FormControl(null, [Validators.required])
    });
  }
}
