// Model
import { Banner } from './../models/banner.model';
import { ApiResponse } from '../models/api-response.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { Livechat } from '@core/models/livechat.model';

@Injectable({
  providedIn: 'root'
})
export class LivechatHttpService {

  private liveChatScript$ = new Subject<Livechat>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) { }

  getLiveChatScript(Param: string): Observable<any> {
    return this.http.get<ApiResponse>(`/livechat/getscript${Param}`).pipe(
      map(res => {
        const rows = res.data?.rows || {};
  
        const liveChatScript = {
          website: rows.website || '',
          chat_page: rows.chat_page || '',
          live_chat_platform_id: rows.live_chat_platform_id || '',
          live_chat_platform_name: rows.live_chat_platform_name || '',
          live_chat_locale_id: rows.live_chat_locale_id || '',
          language_code: localStorage.getItem('language_code') || '',
          country_code: localStorage.getItem('country_code') || ''
        };

        sessionStorage.setItem('live_chat_script', JSON.stringify(liveChatScript));
        return res.data;
      }),
    );
  }

  liveChatScriptWebsocket() {
    this.websocketService
      .getChannel('bp77-staging-update-live-chat-channel')
      .listen('.UpdateLiveChatEvent', (liveChat: Livechat) => {
        const newLiveChat : Livechat = {
          live_chat_locale_id: liveChat.live_chat_locale_id,
          website: liveChat.website,
          chat_page: liveChat.chat_page,
          live_chat_platform_id: liveChat.live_chat_platform_id,
          live_chat_platform_name: liveChat.live_chat_platform_name,
        };
        const storedLiveChat = this.getLiveChatScriptFromSession();

         // Check if live_chat_local_id matches and if script and changes to country and language
         const isDifferentContent =
         storedLiveChat &&
         storedLiveChat.live_chat_locale_id === newLiveChat.live_chat_locale_id &&
         (storedLiveChat.website !== newLiveChat.website ||
           storedLiveChat.language_code !== localStorage.getItem('language_code') ||
           storedLiveChat.country_code !== localStorage.getItem('country_code'));

       if (isDifferentContent) {
         sessionStorage.setItem('live_chat_script', JSON.stringify(newLiveChat));
         this.liveChatScript$.next(newLiveChat);
       }
      });
  }

  getScriptUpdates() {
    return this.liveChatScript$.asObservable();
  }

  getLiveChatScriptFromSession(key?: string): any {
    const liveChatScript = JSON.parse(sessionStorage.getItem('live_chat_script') || '{}');
    return key ? liveChatScript[key] : liveChatScript;
  }

  updateLiveChatScriptInSession(key: string, value: string): void {
    const liveChatScript = this.getLiveChatScriptFromSession();
    liveChatScript[key] = value;
    sessionStorage.setItem('live_chat_script', JSON.stringify(liveChatScript));
  }
}
