import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';
import { BankHttpService } from '@core/services/bank-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { MemberBankHttpService } from '@core/services/member-bank-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ForgotPasswordModalComponent } from '@shared/forgot-password-modal/forgot-password-modal.component';
import { InsufficientModalModalComponent } from '@shared/insufficient-funds-modal/insufficient-funds-modal.component';
import { AppState } from 'app/store/reducers';
import { of, Subscription, forkJoin, Observable } from 'rxjs';
import { catchError, delay, tap, map } from 'rxjs/operators';
import { RewardSwalComponent } from './../../views/modules/member/dialogs/reward-modal/reward-swal/reward-swal.component';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import { Crisp } from "crisp-sdk-web";
import { LivechatHttpService } from '@core/services/livechat-http.service';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import svgIconList from 'assets/icons.json';
import { Livechat } from '@core/models/livechat.model';

declare const window: any;
declare var $: any;
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  @ViewChildren('passwordField') passwordField: QueryList<ElementRef>;
  mode: string = window.mode;
  messages$ = this.auth.messages$;
  form: FormGroup;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };
  loading = false;
  checkValidity = false;
  refreshStatus: boolean;

  isSlotsPage$ = this.gameProviderHttpService.isSlotsPage$;
  countryCode = localStorage.getItem('country_code');
  languageCode = localStorage.getItem('language_code');

  isLoggedIn$: Observable<boolean>;

  private subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotion?: any },
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private gameProviderHttpService: GameProviderHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private bankHttpService: BankHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private walletHttpService: WalletHttpService,
    private memberBankHttpService: MemberBankHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    private livechatHttpService: LivechatHttpService,
    private cryptoWalletHttpService: CryptoWalletHttpService,
    private route: ActivatedRoute,
) { }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.onPasswordMask();
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
    this.eventEmitterService.selectInputEmitter.subscribe((name: any) => {
      this.onSelectAll();
    });
  }

  ngOnDestroy() {
    this.onRefresh();
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog() {
    this.dialog.open(ForgotPasswordModalComponent)
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid && !this.loading) {
      sessionStorage.setItem('initialLogin', '1');
      this.loading = true;
      this.loadingBar.start();
      const { username, password } = this.form.value;
      this.auth.login(username, password).pipe(
        tap((res) => {

          if(res){
            this.eventEmitterService.onPopUpAnnouncementEmitter();
            this.eventEmitterService.onUpdateVIPEmitter();
          }
          
          this.store.dispatch(fromAuthActions.login({
            user: res.data.user,
            token: res.data.token,
          }));
          localStorage.setItem('verified_number', res.data.user.phone_validated);
          localStorage.setItem('reward_notice', res.data.user.pending_reward);
          sessionStorage.setItem('session_user_checker', 'logged in');
          this.setSessionStorage(res);
          // if (res.data.user.pending_reward === 1) {
          //   this.onRewardNotice();
          // }
          this.onCloseDialog();

          // Set livechat name and email after login
          try {
            this.getLiveChatScript();
            if (localStorage.getItem('user_data') != null) {
              let username = JSON.parse(localStorage.getItem('user_data')).username;
              let email = JSON.parse(localStorage.getItem('user_data')).email;
              
              if (username != null && username !== '') {
                if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Live Chat') {
                  window.LiveChatWidget.call("set_customer_name", username);
                } else if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Crisp') {
                  Crisp.user.setNickname(username);
                }
              }
              if (email != null && email !== '') {
                if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Live Chat') {
                  window.LiveChatWidget.call("set_customer_email", email);
                } else if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Crisp') {
                  Crisp.user.setEmail(email);
                }
              } 
            }
          } catch {}

          of(null).pipe(
            delay(1000), tap(() => {
              this.eventEmitterService.onTopBarTabEmitter();
            }
            )).subscribe();
          if (window.location.pathname.startsWith('/slots-game/')) {
            this.gameProviderHttpService.isSlotsPage$.next(true);
          }
          this.loading = false;
          this.loadingBar.complete();
          this.checkValidity = false;
        }), catchError(err => {
          this.loading = false;
          this.loadingBar.complete();
          return err;
        })
      ).subscribe(
        response => {
          this.route.queryParams.subscribe(async (params: Params) => {
            if (window.location.pathname.includes('signup') || params['campaign'] !== undefined) {
              this.router.navigateByUrl('/');
            }
          });
        }
      )
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private refreshBalance(res: any) {
    let wallet = this.walletHttpService.memberBalance.value;
    this.dashboardService.refreshMemberBalance(wallet.balance);
    if (this.data !== null) {
      this.promotionApplication(this.data.promotion)
    }
  }

  private setSessionStorage(data: any) {
    this.bankHttpService.getAllBanks().subscribe(res => {
      sessionStorage.setItem('all_bank', JSON.stringify(res));
    });
    this.refreshBalance(data);
    this.merchantBankHttpService.getMerchantBankDeposits().subscribe(res => {
      sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
      this.setBankMaintenanceHour();
    });
    this.cryptoWalletHttpService.getAll().subscribe(res => {
      sessionStorage.setItem('member_crypto_accounts', JSON.stringify(res))
    });
    this.memberBankHttpService.getMemberBanks().subscribe(res => {
      sessionStorage.setItem('member_bank_accounts', JSON.stringify(res));
    });
  }

  private onRewardNotice() {
    this.dialog.open(RewardSwalComponent, {
      width: '800px',
      data: {
        type: 'notice'
      }
    });
  }

  private promotionApplication(promo?: any) {
    if (+promo.apply_action === 2) {
      this.router.navigateByUrl('/member/transfer');
    } else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  private onSelectAll() {
    this.passwordField.first.nativeElement.select();
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef);
  }

  private setBankMaintenanceHour(){
    if(sessionStorage.getItem('member_merchant_bank') !== null){
      let bank_ids = [];
      JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
        if(merchant_banks['bank_id']){
          bank_ids.push(merchant_banks['bank_id']);
        }
      });
      this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
        localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
      });
      
    }
  }      

  
  private getLiveChatScript() {
    const language = localStorage.getItem('language_code') || '';
    const country = localStorage.getItem('country_code') || '';
    const storedLiveChat = this.livechatHttpService.getLiveChatScriptFromSession();

    // Check if the stored script exists or if the language/country has changed
    const hasLocaleChanged = !storedLiveChat ||
      storedLiveChat.language_code !== language ||
      storedLiveChat.country_code !== country;

    if (!hasLocaleChanged) {
      this.loadChatScript(storedLiveChat);
    } else {
      this.livechatHttpService.getLiveChatScript(`?language=${language}&country=${country}`).subscribe(res => {
        if (res.rows && res.rows.website) {
          const storedLiveChat = this.livechatHttpService.getLiveChatScriptFromSession();
          this.loadChatScript(storedLiveChat);
        }
      });
    }

    // Start WebSocket listener for script updates
    this.livechatHttpService.liveChatScriptWebsocket();

    // Subscribe to live chat script updates
    this.livechatHttpService.getScriptUpdates().subscribe(liveChat => {
      this.loadChatScript(liveChat);
    });
  }

  private loadChatScript(liveChat: Livechat) {
    if (liveChat.website !== null && liveChat.website !== '') {
      this.isLoggedIn$.subscribe(login => {
        var website = liveChat.website;

        // Check live chat <script> is it exists.
        var liveChatScriptID = "live-chat-script";
        var scriptIndex = website.indexOf('<script') + '<script'.length;
        website = website.slice(0, scriptIndex) + ' id="' + liveChatScriptID + '" ' + website.slice(scriptIndex, website.length);
        var scriptExists = (document.getElementById(liveChatScriptID) !== null);

        if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Live Chat') {
          // Remove old live chat <script> if already exists.
          if (scriptExists) {
            var scriptElement = document.getElementById(liveChatScriptID);
            if (scriptElement != null) {
              scriptElement.parentNode.removeChild(scriptElement);
            }
          }

          website = website.replace(/(['])/g, "\\$1");
          var index = website.indexOf('window.__lc.license');
          var username = "  ";
          var email = "  ";

          if (login) {
            if (localStorage.getItem('user_data') != null) {
              if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                username = JSON.parse(localStorage.getItem('user_data')).username;
              }
              if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                email = JSON.parse(localStorage.getItem('user_data')).email;
              }
            }
            if(index > 0) {
              website = website.slice(0, index) + "window.__lc.visitor = {name: '"+ username +"',email: '"+ email +"'};" + website.slice(index, website.length);
            }
          }
          else{
            if(index > 0) {
              website = website.slice(0, index) + website.slice(index, website.length);
            }
          }
          $(website).appendTo(document.body);
          this.livechatHttpService.updateLiveChatScriptInSession('website', website);
        } else if (this.livechatHttpService.getLiveChatScriptFromSession('live_chat_platform_name') == 'Crisp') {

          // Crisp no need to remove and assign again due to after assign it require refresh only can work.
          if (!scriptExists || typeof window.$crisp === "undefined") {
            $(website).appendTo(document.body);
            this.livechatHttpService.updateLiveChatScriptInSession('website', website);
            var indexStart = website.indexOf('window.CRISP_WEBSITE_ID=')  + 'window.CRISP_WEBSITE_ID='.length;
            var indexEnd = website.indexOf(';(function(){d=document');
            var websiteID = website.slice(indexStart, indexEnd).replace(/\\/g, "").replace(/\"/g, "");

            Crisp.configure(websiteID);
          }

          if (login) {
            if (localStorage.getItem('user_data') != null) {
              if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                Crisp.user.setNickname(JSON.parse(localStorage.getItem('user_data')).username);
              }
              if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                Crisp.user.setEmail(JSON.parse(localStorage.getItem('user_data')).email);
              }
            }
          }

        }
      });
    }
  }
}
