import { MemberComponent } from './member.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@shared/shared.module';
import { ViewsModule } from '@views/views.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LightboxModule } from 'ngx-lightbox';
import { PortalBankComponent } from './pages/bank/portal-bank.component';
import { PortalChangePasswordComponent } from './pages/change-password/portal-change-password.component';
import { PortalDepositComponent } from './pages/deposit/portal-deposit.component';
import { PortalGamePasswordComponent } from './pages/game-password/portal-game-password.component';
import { HistoryDetailsComponent } from './pages/history-details/history-details.component';
import { PortalHistoryComponent } from './pages/history/portal-history.component';
import { PortalAffiliateComponent } from './pages/member-affiliate/portal-affiliate.component';
import { MessageDetailsComponent } from './pages/message-details/message-details.component';
import { PortalMessageComponent } from './pages/message/portal-message.component';
import { PortalNavigationComponent } from './pages/navigation/portal-navigation.component';
import { PortalProfileComponent } from './pages/profile/portal-profile.component';
import { ProgressInfoComponent } from './pages/progress-info/progress-info.component';
import { ReferralHistoryComponent } from './pages/referrals/referral-history/referral-history.component';
import { PortalRewardComponent } from './pages/reward/portal-reward.component';
import { PortalStatusComponent } from './pages/status/portal-status.component';
import { PortalTransferComponent } from './pages/transfer/portal-transfer.component';
import { PortalVerificationComponent } from './pages/verification/portal-verification.component';
import { PortalVipComponent } from './pages/vip/portal-vip.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { PortalWithdrawComponent } from './pages/withdraw/portal-withdraw.component';
import { RouterModule, Routes } from '@angular/router';
import { BankModalComponent } from './dialogs/bank-modal/bank-modal.component';
import { PromotionTooltipDialogComponent } from './dialogs/transfer-modal/promotion-tooltip/promotion-tooltip-modal.component';
import { ClaimRewardModalComponent } from './dialogs/reward-modal/claim-reward-modal.component';
import { RewardSwalComponent } from './dialogs/reward-modal/reward-swal/reward-swal.component';
import { TransferEditModalComponent } from './dialogs/transfer-modal/transfer-edit-modal/transfer-edit-modal.component';
import { WithdrawVerificationModalComponent } from './dialogs/withdraw-verification-modal/withdraw-verification-modal.component';
import { PromotionCancelModalComponent } from './dialogs/promotion-cancel-modal/promotion-cancel-modal.component';
import { UploadReceiptModalComponent } from './dialogs/upload-receipt-modal/upload-receipt-modal.component';
import { TransactionReceiptModalComponent } from './dialogs/transaction-receipt-modal/transaction-receipt-modal.component';
import { ViewReceiptModalComponent } from './dialogs/view-receipt-modal/view-receipt-modal.component';
import { ReceiptModalComponent } from './dialogs/receipt-modal/receipt-modal.component';
import { CryptoWalletModalComponent } from './dialogs/crypto-wallet-modal/crypto-wallet-modal.component';
import { CryptoWalletDetailsModalComponent } from './dialogs/crypto-wallet-details-modal/crypto-wallet-details-modal.component';
import { CopyInputComponent } from './pages/deposit/copy-input/copy-input.component';
import { DepositDeactivateGuard } from '@core/guards/deposit-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'wallet',
        component: WalletComponent,
        data: {
          title: 'My Wallet'
        }
      },
      {
        path: 'transfer',
        component: PortalTransferComponent,
        data: {
          title: 'Transfer'
        }
      },
      {
        path: 'deposit',
        component: PortalDepositComponent,
        data: {
          title: 'Deposit'
        },
        canDeactivate: [DepositDeactivateGuard],
      },
      {
        path: 'withdraw',
        component: PortalWithdrawComponent,
        data: {
          title: 'Withdraw'
        }
      },
      {
        path: 'history/:type',
        component: PortalHistoryComponent,
        data: {
          title: 'History'
        }
      },
      {
        path: 'history/:type/:id',
        component: HistoryDetailsComponent,
        data: {
          title: ' History'
        }
      },
      {
        path: 'profile',
        component: PortalProfileComponent,
        data: {
          title: 'My Profile'
        }
      },
      {
        path: 'change-password',
        component: PortalChangePasswordComponent,
        data: {
          title: 'Change Password'
        }
      },
      {
        path: 'game-password',
        component: PortalGamePasswordComponent,
        data: {
          title: 'Game Password'
        }
      },
      {
        path: 'bank',
        component: PortalBankComponent,
        data: {
          title: 'Bank Details'
        }
      },
      {
        path: 'message',
        component: PortalMessageComponent,
        data: {
          title: 'Messages'
        }
      },
      {
        path: 'message/:id',
        component: MessageDetailsComponent,
        data: {
          title: 'Message'
        }
      },
      {
        path: 'referrals-history',
        component: ReferralHistoryComponent,
        data: {
          title: 'Referral'
        }
      },
      {
        path: 'reward',
        component: PortalRewardComponent,
        data: {
          title: 'Reward'
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Settings'
        }
      },
      {
        path: 'verification',
        component: PortalVerificationComponent,
        data: {
          title: 'Verification'
        }
      },
      {
        path: 'verification-info',
        component: ProgressInfoComponent,
        data: {
          title: 'Verification Info'
        }
      },
      {
        path: 'vip',
        component: PortalVipComponent,
        data: {
          title: 'VIP'
        }
      },
      {
        path: 'status',
        component: PortalStatusComponent,
        data: {
          title: 'Status'
        }
      },
      {
        path: 'account',
        component: PortalNavigationComponent,
        data: {
          title: 'Account'
        }
      },
      {
        path: '**', redirectTo: '/member/deposit'
      }
    ],
  }
];

@NgModule({
  declarations: [
    MemberComponent,
    PortalDepositComponent,
    PortalHistoryComponent,
    PortalWithdrawComponent,
    PortalVipComponent,
    PortalTransferComponent,
    PortalProfileComponent,
    SettingsComponent,
    PortalMessageComponent,
    PortalAffiliateComponent,
    PortalStatusComponent,
    PortalBankComponent,
    PortalVerificationComponent,
    PortalChangePasswordComponent,
    PortalNavigationComponent,
    PortalRewardComponent,
    WalletComponent,
    ReferralHistoryComponent,
    MessageDetailsComponent,
    ProgressInfoComponent,
    HistoryDetailsComponent,
    PortalStatusComponent,
    PortalGamePasswordComponent,
    UploadReceiptModalComponent,
    TransactionReceiptModalComponent,
    ViewReceiptModalComponent,
    ReceiptModalComponent,
    CryptoWalletModalComponent,
    CryptoWalletDetailsModalComponent,
    CopyInputComponent
  ],

  imports: [
    RouterModule.forChild(routes),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SharedModule,
    ViewsModule,
    MatNativeDateModule,
    MatTooltipModule,
    LightboxModule
  ],
  exports: [RouterModule],
  entryComponents: [
    BankModalComponent,
    RewardSwalComponent,
    ClaimRewardModalComponent,
    PromotionTooltipDialogComponent,
    TransferEditModalComponent,
    WithdrawVerificationModalComponent,
    PromotionCancelModalComponent,
    UploadReceiptModalComponent,
    TransactionReceiptModalComponent,
    ViewReceiptModalComponent,
    ReceiptModalComponent,
    CryptoWalletDetailsModalComponent,
    CryptoWalletModalComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ]
})
export class MemberModule {
  // constructor(
  //   private eds: EntityDefinitionService,
  //   private entityDataService: EntityDataService,
  //   private bankAccountDataService: BankAccountDataService,
  //   private historyDataService: HistoryDataService,
  //   private rewardDataService: RewardDataService
  // ) {
  //   eds.registerMetadataMap(entityMetadata);
  //   entityDataService.registerService('BankAccount', bankAccountDataService);
  //   entityDataService.registerService('History', historyDataService);
  //   entityDataService.registerService('Reward', rewardDataService);
  // }
}
