<div class="modal show" id="claimed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-body">
      <div class="modal-content modal-bg modal-bg-wallet">
        <div class="modal-header pb-0" *ngIf="data.type === 'notice'">
          <button type="button" class="close" (click)="onClaim('close')">&times;</button>
        </div>
        <div class="claimed-modal pt-0">
          <ng-container *ngIf="data.type === 'success'; else elseNotice">
            <img src="/assets/images/message-icon/claimed-icon.png">
            <p [innerHTML]="data.message"></p>
            <ng-container *ngIf="data.provider !== null && data.provider !== undefined">
              <p class="text-blue" *ngIf="(data.provider.code === 'KISS' || data.provider.code === 'MEGA' || data.provider.code === 'MEGAC')">
                <ng-container *ngIf="countryCode === 'ID'">
                    {{ 'Exchange Rate' | translate }}<br> IDR 1:0.2  {{ data.provider.name | uppercase }} {{ 'Credits' | translate }}
                </ng-container>

                <ng-container *ngIf="countryCode === 'MY'">
                    {{ 'Exchange Rate' | translate }}<br> MYR 1:1  {{ data.provider.name | uppercase }} {{ 'Credits' | translate }}
                </ng-container>

                <ng-container *ngIf="countryCode === 'SG'">
                    {{ 'Exchange Rate' | translate }}<br> SGD 1:1  {{ data.provider.name | uppercase }} {{ 'Credits' | translate }}
                </ng-container>

                <ng-container *ngIf="countryCode === 'TH'">
                    {{ 'Exchange Rate' | translate }}<br> THB 10:1  {{ data.provider.name | uppercase }} {{ 'Credits' | translate }}
                </ng-container>
              </p>
            </ng-container>
            <button class="btn-blue-gra m-t-20 m-b-20" (click)="onCloseDialog()">OK</button>
          </ng-container>
          <ng-template #elseNotice>
            <img class="mt-0" src="/assets/images/message-icon/reward-message.png">
            <p>{{ 'You have a reward pending to be claimed!' | translate }}</p>
            <h3>{{ 'Reward' | translate }}!</h3>
            <button class="btn-yellow-gra m-t-20 m-b-20" (click)="onClaim('navigate')">{{ 'CLAIM NOW' | translate }}</button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>