import { Component, OnInit } from '@angular/core';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-progress-info',
  templateUrl: './progress-info.component.html',
  styleUrls: ['./progress-info.component.scss'],
})
export class ProgressInfoComponent implements OnInit {
  svg: any = svgIconList;

  profile: PortalMemberProfile;

  defaultTab = 0;
  currentTab = 0;
  userData = JSON.parse(localStorage.getItem('user_data'));

  KYCStage = {
    'basic': 0,
    'advanced': 1,
    'pro': 2
  }
  
  constructor(
    private memberProfileService: PortalMemberProfileHttpService,
  ) { }

  ngOnInit() {
    this.profile = this.userData;
    this.defaultTab = this.KYCStage[this.userData.KYC];
  }

  onChangeTab($event) {
    this.currentTab = $event.index;
  }
}
