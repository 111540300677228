import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/reducers';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {
  svg: any = svgIconList;

  mode: string = window.mode;
  local = localStorage;
  availableCountryAndLanguages = JSON.parse(JSON.stringify(this.dropdownService.availableCountryAndLanguages)); // To Clone
  languages = this.dropdownService.languages;

  isLoggedIn = false;
  currentCountryCode = localStorage.getItem('country_code')?.toUpperCase();
  currentLanguageCode = localStorage.getItem('language_code')?.toUpperCase();
  currentDomain: string;
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  hiddenDomains = this.dropdownService.vipHiddenDomains;

  constructor(
    private dropdownService: DropdownHttpService,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private authService: AuthHttpService,
    public dialogRef: MatDialogRef<LanguageModalComponent>,) { }

  ngOnInit() {
    // Get current domain
    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }
    // First Alternative 
    if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainName]).includes('"' + this.hostname + '"') ||
      environment.altDomainName.split('|').includes(this.hostname)
    ) {
      this.currentDomain = environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      this.currentDomain = environment.altDomainNameTwo;
    }
    // Default
    else {
      this.currentDomain = environment.domainName;
    }
    this.store.pipe(select(isLoggedIn)).subscribe(value => {
      this.isLoggedIn = value
      if (this.isLoggedIn) {
        this.availableCountryAndLanguages = JSON.parse(JSON.stringify(this.dropdownService.availableCountryAndLanguages));
      } else {
        delete this.availableCountryAndLanguages[this.currentDomain]['TH'];
      }
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  setLanguage(languageCode: string, countryCode: string) {
    sessionStorage.removeItem('promotionSession');
    sessionStorage.removeItem('slotSession');
    sessionStorage.removeItem('fishingSession');
    sessionStorage.removeItem('crashGameSession');
    sessionStorage.removeItem('lotterySession');
    sessionStorage.removeItem('tableSession');
    sessionStorage.removeItem('casinoSession');
    sessionStorage.removeItem('live_chat_script');

    localStorage.setItem('country_code', countryCode);
    localStorage.setItem('language_code', languageCode);

    this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if (!res) {
        localStorage.setItem('isLanguageChanged', '1');
      }
    });

    this.translateService.use(languageCode.toLowerCase());
    this.translateService.setDefaultLang(languageCode.toLowerCase());

    this.onCloseDialog();

    if (this.isLoggedIn) {
      this.authService.updateLocale().subscribe(_ => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        userData = { ...userData, lang_code: languageCode };
        localStorage.setItem('user_data', JSON.stringify(userData));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
    } else {
      var urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('lang');
      urlParams.append('lang', countryCode + '_' + languageCode);
      window.location.assign((environment.https ? 'https://' : 'http://') + window.location.hostname + window.location.pathname + '?' + urlParams.toString());
    }
  }

  returnZero() {
    return 0;
  }
}
